import { FC, forwardRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import Accordion, { AccordionProps } from '@mui/material/Accordion'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'

import Icon from '@/@core/components/icon'

const minusIcon = (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15.968' r='14.5' fill='#fff' stroke='#A8C0F9' />
    <path
      d='M13.364 10.554a2 2 0 0 1 2.828 0l7.779 7.778a2 2 0 0 1-2.829 2.828l-7.778-7.778a2 2 0 0 1 0-2.828'
      fill='#A8C0F9'
    />
    <path
      d='M16.192 10.554a2 2 0 0 1 0 2.828L8.414 21.16a2 2 0 0 1-2.828-2.828l7.778-7.778a2 2 0 0 1 2.828 0'
      fill='#A8C0F9'
    />
  </svg>
)

const plusIcon = (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15.968' r='14.5' fill='#fff' stroke='#A8C0F9' />
    <path
      d='M16.192 21.16a2 2 0 0 1-2.828 0l-7.778-7.777a2 2 0 1 1 2.828-2.829l7.778 7.778a2 2 0 0 1 0 2.829'
      fill='#A8C0F9'
    />
    <path
      d='M13.364 21.16a2 2 0 0 1 0-2.828l7.778-7.778a2 2 0 1 1 2.829 2.829l-7.779 7.778a2 2 0 0 1-2.828 0'
      fill='#A8C0F9'
    />
  </svg>
)

export const StyledAccordion: FC<AccordionProps> = styled(
  forwardRef<HTMLDivElement, AccordionProps>((props, ref) => (
    <Accordion disableGutters elevation={0} square ref={ref} {...props}>
      {props.children}
    </Accordion>
  ))
)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

export const StyledAccordionSummary: FC<AccordionSummaryProps> = styled(
  forwardRef<HTMLDivElement, AccordionSummaryProps>((props, ref) => (
    <AccordionSummary
      expandIcon={<Icon icon={'iconamoon:arrow-down-2'} width={26} height={26} />}
      ref={ref}
      {...props}
    />
  ))
)(({ theme }) => ({
  borderRadius: '6.86px',
  background: '#FFF',
  boxShadow: '0px 1.9px 6.1px rgba(8, 15, 52, 0.06)',

  '&.Mui-expanded': { borderBottom: '1px solid #A796F2' },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#1678FB',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    color: '#000E21',
    fontSize: '18px',
    lineHeight: '1.625rem',
    letterSpacing: '0.0015rem',
    fontWeight: 400
  }
}))

export const FaqStyledAccordion: FC<AccordionProps> = styled(
  forwardRef<HTMLDivElement, AccordionProps>((props, ref) => (
    <Accordion disableGutters elevation={0} square ref={ref} {...props}>
      {props.children}
    </Accordion>
  ))
)(({ theme }) => ({
  boxShadow: 'none !important',
  border: '2px solid #D7E3FF',
  borderRadius: '25px',
  marginBottom: '15px !important',
  '&:last-child': {
    marginBottom: '0px !important'
  },
  '&:before': {
    display: 'none'
  }
}))

export const FaqStyledAccordionSummary: FC<AccordionSummaryProps> = styled(
  forwardRef<HTMLDivElement, AccordionSummaryProps>((props, ref) => {
    // const [isExpanded, setIsExpanded] = useState(false);

    return (
      <AccordionSummary
        expandIcon={<div className={'xs:[&_svg]:w-[24px]'}>{props?.expanded ? minusIcon : plusIcon}</div>}
        icon={plusIcon}
        ref={ref}
        {...props}
      />
    )
  })
)(({ theme }) => ({
  borderRadius: '25px',
  background: '#FFF',
  border: 'none',
  paddingLeft: '15px',
  paddingRight: '15px',

  '&.Mui-expanded': {
    boxShadow: '1px 5px 2px rgba(0, 0, 0, 0.13)',
    borderRadius: '25px',
    borderBottom: 'none !important'
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none' // No rotation needed since icons change dynamically
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#5D78FF',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    color: '#000E21',
    fontSize: '18px',
    lineHeight: '1.625rem',
    letterSpacing: '0.0015rem',
    fontWeight: 400
  }
}))
